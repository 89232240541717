import axios from 'axios';
import { useNavigate } from 'react-router-dom';

export const Api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  // baseURL: 'https://api.bestcabins.wocom.biz/v1',

  timeout: 100000,
});

Api.interceptors.request.use(function (config) {
  const authToken = window.localStorage.getItem('authToken');

  if (authToken) {
    config.headers = {
      Authorization: `Bearer ${authToken}`,
      'Content-Type': 'application/json',
    };
  }

  return config;
});

Api.interceptors.response.use(function (response) {
  return response;
});

export const apiPost = async ({ url, postData = {}, headerList = {} }) => {
  const res = await Api.post(url, postData, { headers: headerList });

  return res.data;
};

export const apiGet = async ({ url, headerList = {} }) => {
  const res = await Api.get(url, { headers: headerList });

  return res.data;
};
