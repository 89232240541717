import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import { apiGet, apiPost } from '../../../api';
import { useNavigate, useParams } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import toast from 'react-hot-toast';
import errorHandler from '../errorHandler';
import ClientRentObjects from '../clientRentObjects';
import AttachNewProductToContract from '../../common/dialogs/attachNewProductToContract';
import { setProductStatusList } from '../../../store/products';
import { useDispatch } from 'react-redux';
import { styled } from '@mui/material/styles';
import { dateReverseFormat } from '../../../helpers/DateHelper';

const StyledBox = styled(Box)(({ theme }) => ({
  [theme.breakpoints.between(0, 430)]: {
    flexDirection: 'column',
    paddingRight: 36,
  },
}));
const StyledDatePicker = styled(TextField)(({ theme }) => ({
  [theme.breakpoints.between(0, 430)]: {
    margin: 0,
  },
}));

const EachContractInfo = () => {
  const { control, handleSubmit } = useForm();
  const [contractInfo, setContractInfo] = useState([]);
  // const [contractProducts, setContractProducts] = useState([])
  const [isFetch, setIsFetch] = useState(false);
  const [contractStatus, setContractStatus] = useState([]);

  const dispatch = useDispatch();
  const { contractId } = useParams();
  const navigate = useNavigate();

  const [startDateContract, setStartDateContract] = useState(null);
  const [stopDateContract, setStopDateContract] = useState(null);
  const [checkChangeRentContract, setCheckChangeRentContract] = useState('');
  const [userAccess, setUserAccess] = useState('');

  useEffect(() => {
    const userAccessLocal = JSON.parse(
      window.localStorage.getItem('userAccess'),
    );
    setUserAccess(userAccessLocal);

    // Записать все доступные статусы для товаров
    apiGet({
      url: '/product/status-list',
    }).then((res) => {
      if (res.error) return errorHandler(res.data);
      dispatch(setProductStatusList(res.data));
    });

    // Получение списка статусов для договора
    apiGet({
      url: '/client-contract/status-list',
    }).then((res) => {
      if (res.error)
        return toast.error(
          'Ошибка получения статусов - пожалуйста перезагрузите страницу',
        );
      setContractStatus(res.data);
    });

    apiGet({
      url: `/client-contract/contract-view?id=${contractId}`,
    })
      .then((res) => {
        console.log(res.data);
        if (res.error) {
          if (res.status === 404) {
            navigate('/404');
          }
          return errorHandler(res.data);
        }
        setContractInfo(res.data);
        setIsFetch(true);

        setStartDateContract(res.data.start);
        setStopDateContract(res.data.end);

        // Сохранение всех товаров внутри этого договора
        // setContractProducts(res.data.product)
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  const submit = (data) => {
    for (let key in data) {
      if (data[key] === undefined) delete data[key];
    }

    if (data?.number === undefined) data.number = contractInfo.number;

    if (startDateContract !== null) data.start = startDateContract;

    if (stopDateContract !== null) data.end = stopDateContract;

    apiPost({
      url: `/client-contract/update?id=${contractId}`,
      postData: data,
    }).then((res) => {
      if (res.error) return errorHandler(res.data);
      toast.success('Обновлено');
      navigate(-1);
    });
  };

  const startDateOnChange = (e) => {
    setStartDateContract(e.target.value);
  };

  const stopDateOnChange = (e) => {
    setStopDateContract(e.target.value);
  };

  if (!isFetch) {
    return <></>;
  } else {
    return (
      <div>
        <Typography
          align='right'
          variant='h4'
        >
          Договор {contractInfo.number}
        </Typography>
        <br />
        <Divider />
        <br />
        <Box sx={{ '& .MuiTextField-root': { m: 2 } }}>
          <form onSubmit={handleSubmit(submit)}>
            <Card
              raised
              sx={{ my: 2 }}
            >
              <FormControl fullWidth>
                <Controller
                  name='number'
                  control={control}
                  render={({ field: { onChange, onBlur } }) => (
                    <TextField
                      name='number'
                      label='Имя договора'
                      disabled={!userAccess['client-contract']?.update}
                      defaultValue={contractInfo?.number}
                      onBlur={onBlur}
                      onChange={onChange}
                    />
                  )}
                />
              </FormControl>

              <FormControl fullWidth>
                <Controller
                  name='description'
                  control={control}
                  render={({ field: { onChange, onBlur } }) => (
                    <TextField
                      name='description'
                      label='Описание'
                      disabled={!userAccess['client-contract']?.update}
                      defaultValue={
                        contractInfo?.description === null
                          ? ''
                          : contractInfo?.description
                      }
                      onBlur={onBlur}
                      onChange={onChange}
                    />
                  )}
                />
              </FormControl>

              <FormControl fullWidth>
                <Controller
                  name='status'
                  control={control}
                  render={({ field: { onChange, onBlur } }) => (
                    <TextField
                      select
                      name='status'
                      disabled={!userAccess['client-contract']?.update}
                      onBlur={onBlur}
                      onChange={onChange}
                      label={'Статус'}
                      defaultValue={contractInfo?.status}
                    >
                      {contractStatus.map((item) => (
                        <MenuItem
                          key={item.key}
                          value={item.key}
                        >
                          {item.value}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              </FormControl>

              <StyledBox sx={{ display: 'flex' }}>
                <StyledDatePicker
                  onChange={startDateOnChange}
                  fullWidth
                  sx={{ mr: 2 }}
                  name='startDate'
                  label='С какого'
                  disabled={!userAccess['client-contract']?.update}
                  type='date'
                  value={dateReverseFormat(startDateContract) || ''}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />

                <StyledDatePicker
                  onChange={stopDateOnChange}
                  fullWidth
                  sx={{ mr: 2 }}
                  value={dateReverseFormat(stopDateContract) || ''}
                  name='stopDate'
                  label='До какого'
                  disabled={!userAccess['client-contract']?.update}
                  type='date'
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </StyledBox>
              {userAccess['client-contract'].update && (
                <Button
                  sx={{ m: 2 }}
                  type={'submit'}
                  variant={'contained'}
                >
                  Обновить
                </Button>
              )}
            </Card>
          </form>
        </Box>

        {/*Start - Создать доп соглашение*/}
        {userAccess['rental-product'].create && (
          <AttachNewProductToContract
            setCheckChangeRentContract={setCheckChangeRentContract}
          />
        )}
        {/*End - Создать доп соглашение*/}

        <ClientRentObjects
          contract_id={contractId}
          checkChangeRentContract={checkChangeRentContract}
        />
      </div>
    );
  }
};

export default EachContractInfo;
