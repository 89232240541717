//React and redux
import {
  getEventData,
  setDialogEventDescription,
  setEventData,
  setEventsList,
} from '../../../../store/events';
import React, { useState, forwardRef, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';

// Components
// import {TextFieldController} from '../../formControllers';
import { apiGet, apiPost } from '../../../../api';

//@mui
// import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Fade from '@mui/material/Fade';
import Box from '@mui/material/Box';

// Icons
// import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
  DialogTitle,
  Select,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import axios from 'axios';
import { setReloadEvents, setShowingDialog } from '../../../../store/app';
import ConfirmDialog from '../confirm';
import { DeleteForever } from '@mui/icons-material';
// import {setUsersList} from "../../../../store/user";
import { theme } from '../../componentColors';
import errorHandler from '../../../layouts/errorHandler';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';

import docLogo from '../../../../assets/document/doc-logo.jpg';

const Transition = forwardRef(function Transition(props, ref) {
  return (
    <Fade
      ref={ref}
      {...props}
    />
  );
});

const Input = styled('input')({
  display: 'none',
});

import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import DialogActions from '@mui/material/DialogActions';

const DialogEventDescription = ({ getAllTasks }) => {
  const dispatch = useDispatch();
  const isEventDescription = useSelector(
    (state) => state.events.isEventDescription,
  );
  const eventData = useSelector((state) => state.events.eventData);
  const { handleSubmit, control, setValue } = useForm();
  // load file
  const [selectedFile, setSelectedFile] = useState([]);
  const [urlSelectedFile, setUrlSelectedFile] = useState(null);
  const uploadRef = useRef(null);

  const [userAccess, setUserAccess] = useState(null);
  const [roleUser, setRoleUser] = useState('');
  const [statusList, setStatusList] = useState([]);
  const [coordinates, setCoordinates] = useState(null);
  const [commentExecuter, setCommentExecuter] = useState(null);
  const [commentRequest, setCommentRequest] = useState(false);

  const [showModalInWork, setShowModalInWork] = useState(false);
  const [roleList, setRoleList] = useState([]);
  const [usersListFromRole, setUsersListFromRole] = useState([]);
  const [roleOfUser, setRoleOfUser] = useState('');
  const [isUserFetch, setIsUserFetch] = useState(false);

  const [showModalDeletePhoto, setShowModalDeletePhoto] = useState(false);
  const [photoId, setPhotoId] = useState('');

  const [executorRole, setExecutorRole] = useState('');

  const [imageFiles, setImageFiles] = useState(null);

  const saveExecuteCommentOnChange = () => {
    const authToken = window.localStorage.getItem('authToken');

    if (selectedFile.length > 0) {
      let formData = new FormData();

      selectedFile.forEach((file) => {
        formData.append('files[]', file);
      });

      setProgressBar(true);
      axios({
        method: 'POST',
        url: `https://api.bestcabins.wocom.biz/v1/task-executor/upload-document?id=${eventData.id}`,
        data: formData,
        headers: {
          Authorization: `Bearer ${authToken}`,
          'Content-Type': 'multipart/form-data',
        },
      }).then((res) => {
        if (res.data.error) return setProgressBar(false);
        toast.success('Фото загружены');
        setProgressBar(false);
        setUrlSelectedFile(null);
      });
    }
  };

  const closeDialog = () => {
    dispatch(setDialogEventDescription(false));
    setSelectedFile([]);
    dispatch(setReloadEvents(false));
    setUrlSelectedFile(null);
    setValue('status', '');
    setRoleOfUser('');
  };

  const submit = (data) => {
    if (data.status === '') delete data.status;
    const userAccess = JSON.parse(window.localStorage.getItem('userAccess'));

    let url = '';
    if (userAccess.task.update) {
      if (coordinates !== null) data.coordinates = coordinates;

      apiGet({
        url: '/user/auth',
      }).then((res) => {
        if (res.error) return;
        if (res.data.email === eventData?.extendedProps?.user?.username)
          url = `/task-executor/update?id=${eventData.id}`;
        else url = `/task/update?id=${eventData.id}`;
        data.comment = commentExecuter;
        if (roleUser === 'admin') url = `/task/update?id=${eventData.id}`;

        getApiPost();
      });
    } else {
      if (commentExecuter !== null) data.comment = commentExecuter;
      url = `/task-executor/update?id=${eventData.id}`;
    }
    data.user_id = roleOfUser;

    if (selectedFile.length > 0) {
      saveExecuteCommentOnChange();
    }

    function getApiPost() {
      apiPost({
        url: url,
        postData: data,
      })
        .then((res) => {
          if (res.error) return errorHandler(res.data);
          toast.success('Задача обновлена');

          // Обновление всех задач
          getAllTasks();
          // dispatch(getEventData());
          closeDialog();
        })
        .catch((e) => {
          console.log(e);
        });
    }
    getApiPost();
  };

  const changeStatusExecuter = (status) => {
    let data = {};
    data.status = status;

    apiPost({
      url: `/task-executor/update?id=${eventData.id}`,
      postData: data,
    })
      .then((res) => {
        if (res.error) return toast.error(res.data);
        toast.success('Задача обновлена');
        setShowModalInWork(false);

        // Обновление всех задач
        dispatch(getEventData());
      })
      .catch((e) => {
        console.log(e);
      });
    closeDialog();
  };

  const deleteEvent = () => {
    dispatch(setShowingDialog('deleteEvent'));
  };

  const confirmDeleteEvent = () => {
    apiGet({
      url: `/task/delete?id=${eventData.id}`,
    }).then((res) => {
      if (res.error) return toast.error('ошибка удаления');
      toast.success('Задача удалена');

      dispatch(setReloadEvents(true));
      // Обновление всех задач
      getAllTasks();
      // dispatch(getEventData());
      closeDialog();
    });
    dispatch(setDialogEventDescription(false));
  };

  const getEventById = () => {
    apiGet({
      url: `/task/view?id=${eventData?.id}`,
    }).then((res) => {
      if (res.error) closeDialog();
      setImageFiles(res.data.files);
    });
  };

  useEffect(() => {
    if (eventData?.id !== undefined) getEventById();
  }, [eventData]);

  useEffect(() => {
    // Получение всех ролей системы
    apiGet({
      url: '/user-role/index',
    }).then((res) => {
      if (res.error) return toast.error('Ошибка получения ролей');
      setRoleList(res.data);
    });

    const userRole = window.localStorage.getItem('userRole');
    setRoleUser(userRole);
    if (userRole !== 'admin') setCommentRequest(true);

    const userAccessLocal = JSON.parse(
      window.localStorage.getItem('userAccess'),
    );
    setUserAccess(userAccessLocal);

    apiGet({
      url: '/task/status-list',
    })
      .then((res) => {
        if (res.error) return toast.error('Ошибка получения статусов задач');
        setStatusList(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  const deleteFile = (i) => {
    uploadRef.current.value = '';
    const filterArray = selectedFile.filter((_, index) => index !== i);
    const filterUrlArray = urlSelectedFile.filter((_, index) => index !== i);
    setSelectedFile(filterArray);
    setUrlSelectedFile(filterUrlArray);
  };

  const loadFile = (e) => {
    setSelectedFile((prev) => [...prev, ...e.target.files]);

    /* Get files in array form */
    // const files = Array.from(e.target.files);

    /* Map each file to a promise that resolves to an array of image URI */
    Promise.all(
      [...selectedFile, ...e.target.files].map((file) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.addEventListener('load', (ev) => {
            resolve(ev.target.result);
          });
          reader.addEventListener('error', reject);
          reader.readAsDataURL(file);
        });
      }),
    ).then(
      (images) => {
        /* Как только все промисы будут разрешены, обновите состояние с помощью массива url изображения. */
        setUrlSelectedFile(images);
      },
      (error) => {
        console.error(error);
      },
    );
  };

  const openFile = (url) => {
    window.open(url, '_blank', 'noopener, noreferrer');
  };

  const handleShowModalDeletePhoto = (e, id) => {
    e.preventDefault();
    e.stopPropagation();
    setPhotoId(id);
    setShowModalDeletePhoto(true);
  };

  const handleCloseModalDeletePhoto = () => {
    setShowModalDeletePhoto(false);
  };

  const submitDeletePhoto = () => {
    apiGet({
      url: `/task-executor/delete-document?id=${photoId}`,
    }).then((res) => {
      if (res.error) return errorHandler(res.data);
      toast.success('Успешно удалено');
      // handleEventClick(eventData,2)
      handleCloseModalDeletePhoto();
      getEventById();
    });

    // dispatch(getEventData())
    // apiGet({
    //     url: `/task/view?id=${eventData.id}`
    // }).then(res => {
    //     if(res.error)
    //         closeDialog()
    //     setImageFiles(res.data.files)
    //     console.log(res.data.files)
    // })
  };

  useEffect(() => {
    if (
      !eventData?.extendedProps?.role &&
      (eventData?.title === 'Доставить' || eventData?.title === 'Забрать')
    ) {
      setExecutorRole('driver');
      apiGet({
        url: `/user/index?role=driver`,
      }).then((res) => {
        if (res.error) return;
        setUsersListFromRole(res.data.models);
        setRoleOfUser('');
        setIsUserFetch(true);
      });
    } else {
      setExecutorRole(eventData?.extendedProps?.role);
    }
  }, [eventData]);

  const onChangeRoleList = (e) => {
    setExecutorRole(e.target.value);
    setIsUserFetch(false);
    apiGet({
      url: `/user/index?role=${e.target.value}`,
    }).then((res) => {
      if (res.error) return;
      setUsersListFromRole(res.data.models);
      setRoleOfUser('');
      setIsUserFetch(true);
    });
  };

  useEffect(() => {
    if (Boolean(eventData)) {
      apiGet({
        url: `/user/index?role=${eventData?._def?.extendedProps?.role}`,
      }).then((res) => {
        if (res.error) return;
        setUsersListFromRole(res.data.models);
        setRoleOfUser(eventData?._def?.extendedProps?.user?.id);
        setIsUserFetch(true);
      });
    }
  }, [eventData]);

  const onChangeUser = (e) => {
    setRoleOfUser(e.target.value);
  };

  return (
    <div>
      <Box sx={{ textAlign: 'right' }}></Box>
      <Dialog
        fullWidth
        open={isEventDescription}
        maxWidth='md'
        scroll='body'
        onClose={closeDialog}
        TransitionComponent={Transition}
      >
        <DialogContent
          sx={{
            pb: 8,
            px: { xs: 4, sm: 15 },
            pt: { xs: 8, sm: 12.5 },
            position: 'relative',
          }}
        >
          <form onSubmit={handleSubmit(submit)}>
            <Typography
              align={'center'}
              variant={'h5'}
              sx={{ mb: 1 }}
            >
              {eventData?.title}
            </Typography>
            {eventData?.extendedProps?.productName !== null ? (
              <Typography
                align={'center'}
                variant={'h6'}
                sx={{ mb: 2 }}
              >
                Номер брони: {eventData?.extendedProps?.productName}
              </Typography>
            ) : (
              <></>
            )}

            <Box
              sx={{
                display: 'flex',
                justifyContent: 'end',
                alignItems: 'center',
              }}
            >
              <Typography>{eventData?.start?.toLocaleString()}</Typography>
              {userAccess?.task?.delete ? (
                <IconButton
                  color='abortButton'
                  aria-label='delete'
                  onClick={deleteEvent}
                >
                  <DeleteIcon />
                </IconButton>
              ) : (
                <></>
              )}
            </Box>

            <FormControl fullWidth>
              <TextField
                sx={{
                  '& .MuiInputBase-input.Mui-disabled': {
                    WebkitTextFillColor: 'black',
                  },
                }}
                disabled={roleUser === 'admin'}
                label={'Комментарий от исполнителя'}
                name='taskcomment'
                control={control}
                onChange={(e) => setCommentExecuter(e.target.value)}
                defaultValue={
                  eventData?.extendedProps?.taskComment === null
                    ? ''
                    : eventData?.extendedProps?.taskComment
                }
              />
            </FormControl>

            {userAccess?.task?.update ? (
              <FormControl fullWidth>
                <TextField
                  sx={{
                    '& .MuiInputBase-input.Mui-disabled': {
                      WebkitTextFillColor: 'black',
                    },
                    mt: 2,
                  }}
                  type={'text'}
                  disabled={roleUser !== 'admin'}
                  label={'Координаты'}
                  control={control}
                  onChange={(e) => setCoordinates(e.target.value)}
                  defaultValue={
                    eventData?.extendedProps?.taskProduct[0].coordinates ||
                    eventData?.extendedProps?.coordinates
                  }
                />
              </FormControl>
            ) : (
              <></>
            )}
            <TextField
              sx={{ mt: 2 }}
              disabled={roleUser !== 'admin'}
              fullWidth
              multiline
              name={'comment'}
              label={'Комментарий администратора'}
              onChange={(e) => setCommentExecuter(e.target.value)}
              defaultValue={
                eventData?.extendedProps?.description === null
                  ? ''
                  : eventData?.extendedProps?.description
              }
            />

            {roleUser !== 'driver' ? (
              <FormControl fullWidth>
                <Controller
                  name='status'
                  control={control}
                  render={({ field: { value, onChange, onBlur } }) => (
                    <TextField
                      sx={{ my: 2 }}
                      select
                      label={'Статус'}
                      name='status'
                      defaultValue={eventData?.extendedProps?.status}
                      onBlur={onBlur}
                      onChange={onChange}
                    >
                      {statusList.map((item) => (
                        <MenuItem
                          key={item.key}
                          value={item.key}
                        >
                          {item.value}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              </FormControl>
            ) : (
              <div>
                {eventData?.extendedProps?.status === 1 ? (
                  <Button
                    sx={{ my: 3 }}
                    onClick={() => {
                      deleteEvent();
                      setShowModalInWork(true);
                    }}
                    variant={'contained'}
                    style={{ backgroundColor: '#ffac00' }}
                  >
                    В работе
                  </Button>
                ) : (
                  <>
                    <Button
                      sx={{ mr: 3 }}
                      style={{ backgroundColor: '#ff4d00' }}
                      onClick={() => changeStatusExecuter(3)}
                      variant={'contained'}
                    >
                      Не удалось выполнить
                    </Button>
                    <Button
                      sx={{ my: 3 }}
                      style={{ backgroundColor: '#42a644' }}
                      onClick={() => changeStatusExecuter(4)}
                      variant={'contained'}
                    >
                      Выполнено
                    </Button>
                  </>
                )}
              </div>
            )}

            {userAccess?.task?.update ? (
              <>
                <TextField
                  onChange={onChangeRoleList}
                  select
                  fullWidth
                  label={'Выбрать роль'}
                  name={'role'}
                  value={executorRole}
                >
                  {roleList.map((item) => (
                    <MenuItem
                      key={item.role}
                      value={item.role}
                    >
                      {item.name}
                    </MenuItem>
                  ))}
                </TextField>
                {isUserFetch ? (
                  <TextField
                    sx={{ mt: 2 }}
                    onChange={onChangeUser}
                    fullWidth
                    select
                    label={'Выбрать пользователя'}
                    name={'user'}
                    value={roleOfUser || ''}
                  >
                    {usersListFromRole.map((item) => (
                      <MenuItem
                        key={item.id}
                        value={item.id}
                      >
                        {item.profile.surname} {item.profile.name}
                      </MenuItem>
                    ))}
                  </TextField>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <></>
            )}

            {eventData?.extendedProps?.taskProduct?.length !== undefined ? (
              <>
                <Box sx={{ display: 'flex', my: 2 }}>
                  <Typography sx={{ fontWeight: 'bold', ml: 1, my: 1 }}>
                    Товары:{' '}
                  </Typography>
                  {eventData?.extendedProps?.taskProduct?.map((item) => (
                    <Box
                      sx={{
                        border: 1,
                        borderRadius: 1,
                        background: '#ececec',
                        m: 1,
                        px: 1.3,
                        py: 0.3,
                      }}
                      key={item.product.id}
                    >
                      {item.product.name}
                    </Box>
                  ))}
                </Box>
              </>
            ) : null}

            <Box
              sx={{
                display: 'flex',
                justifyContents: 'space-between',
                my: 2,
                flexWrap: 'wrap',
              }}
            >
              <div>
                <Button
                  variant='contained'
                  sx={{
                    mr: 1,
                    [theme.breakpoints.between(0, 450)]: { width: '100%' },
                  }}
                  type={'submit'}
                >
                  Обновить задачу
                </Button>

                <Button
                  sx={{
                    [theme.breakpoints.between(0, 450)]: {
                      marginTop: 1.4,
                      width: '100%',
                    },
                  }}
                  variant='contained'
                  color='secondary'
                  onClick={closeDialog}
                >
                  Отменить
                </Button>
              </div>

              {!!userAccess &&
                userAccess['task-executor']['upload-document'] && (
                  <label htmlFor='icon-button-file'>
                    <Input
                      accept='image/*;capture=camera'
                      id='icon-button-file'
                      type='file'
                      ref={uploadRef}
                      multiple
                      onChange={loadFile}
                    />
                    <IconButton
                      color='primary'
                      component='span'
                    >
                      <AddPhotoAlternateIcon />
                    </IconButton>
                  </label>
                )}
            </Box>
          </form>

          <Box
            type={'span'}
            sx={{ my: 1 }}
          >
            {urlSelectedFile === null ? (
              <></>
            ) : (
              urlSelectedFile.map((item, i) => (
                <Box
                  key={i}
                  sx={{
                    border: '1px solid #d7d7d7',
                    mr: 1,
                    display: 'inline-flex',
                    flexWrap: 'wrap',
                    alignItems: 'center',
                  }}
                >
                  {/*<button onClick={() => console.log()}>show</button>*/}
                  <img
                    style={{ height: '50px' }}
                    src={item.indexOf('data:image') === 0 ? item : docLogo}
                    alt='Photo uploaded'
                  />
                  <IconButton
                    sx={{ width: 32, height: 32 }}
                    onClick={() => deleteFile(i)}
                  >
                    <DeleteForever />
                  </IconButton>
                </Box>
              ))
            )}
          </Box>
          <Accordion sx={{ mt: 2 }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography
                onClick={() => console.log(eventData?.extendedProps?.files)}
              >
                Документы к задаче
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              {imageFiles === null ? (
                <></>
              ) : (
                imageFiles?.map((item) => (
                  <a
                    key={item.id}
                    href={''}
                    style={{ position: 'relative', zIndex: 20 }}
                    onClick={() => openFile(item?.publicLink)}
                  >
                    {userAccess['task-executor']['delete-document'] && (
                      <IconButton
                        onClick={(e) => {
                          handleShowModalDeletePhoto(e, item?.id);
                        }}
                        sx={{
                          position: 'absolute',
                          zIndex: 321,
                          bottom: 70,
                          width: 30,
                          height: 30,
                          right: 4,
                          backgroundColor: 'rgba(255,255,255,0.6)',
                          '&:hover': {
                            backgroundColor: 'rgba(255,255,255,0.9)',
                          },
                        }}
                      >
                        <ClearRoundedIcon />
                      </IconButton>
                    )}
                    <img
                      style={{
                        height: '100px',
                        maxWidth: 200,
                        objectFit: 'cover',
                      }}
                      key={item.id}
                      src={
                        item.typeText === 'Картинка'
                          ? item?.publicLink
                          : docLogo
                      }
                      alt={'Документ'}
                    />
                  </a>
                ))
              )}
            </AccordionDetails>
          </Accordion>
        </DialogContent>

        <Dialog
          open={showModalDeletePhoto}
          onClose={handleCloseModalDeletePhoto}
        >
          <DialogTitle>Удалить Фото/документ?</DialogTitle>
          <DialogContent>
            Удаленное фото/документ невозоможно будет восстановить
          </DialogContent>
          <DialogActions>
            <Button
              color={'abortButton'}
              onClick={submitDeletePhoto}
            >
              Удалить
            </Button>
            <Button
              onClick={handleCloseModalDeletePhoto}
              autoFocus
            >
              Отменить
            </Button>
          </DialogActions>
        </Dialog>
      </Dialog>
      <ConfirmDialog
        title={'Удалить событие?'}
        name='deleteEvent'
        event={confirmDeleteEvent}
        buttonTitle={'Удалить'}
      />
      {showModalInWork ? (
        <ConfirmDialog
          title={'Вы уверены?'}
          event={() => {
            changeStatusExecuter(2);
          }}
          buttonTitle={'Да'}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default DialogEventDescription;
